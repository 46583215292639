<template>
  <div>
    <general-table
      ref="productAdsTable"
      :api-url="apiUrl"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :search-box="false"
      :selectable="false"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template
        #cell(status)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.status)"
        >
          {{ getTypes(data.item.status) }}
        </b-badge>
      </template>
      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.product_id"
              value-field="id"
              text-field="name"
              :options="products"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Product
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.product_id">
              <b-button
                size="sm"
                @click="filter.product_id = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.productAdsTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
    </general-table>

  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/product_ads',
      addType: 'Add New Product Ad',
      addComponentName: 'add-product-ad',
      editComponent: 'edit-product-ad',
      type: 'page',
      viewContent: false,
      products: [],
      filter: {},
      types: '',
      columns: [
        { key: 'image', label: 'Image' },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'product_name', label: 'Product' },
        { key: 'status', label: 'Status' },
        { key: 'actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/product_ads?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  mounted() {
    this.getProducts()
  },
  methods: {

    async getProducts() {
      await axios.get('admin/products').then(res => {
        if (res.status === 200) {
          this.products = res.data?.data.products
        }
      })
    },
    filterTypes(type) {
      const filteredType = this.products.filter(item => item.text !== type)
      this.types = filteredType
    },

    changeVaraint(type) {
    // eslint-disable-next-line eqeqeq
      if (type === true) return 'success'

      return 'danger'
    },

    getTypes(type) {
      if (type === true) {
        return 'Active'
      } if (type === false) {
        return 'Not Active'
      }
      return 'Active'
    },
  },
}
</script>

<style>

</style>
